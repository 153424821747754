import { ChangeDetectionStrategy, Component, HostListener, inject, Input, output } from '@angular/core';
import { ClientView } from '../../type';
import { PuiAvatarModule, PuiCardModule, PuiInputCheckboxModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { FormsModule } from '@angular/forms';
import { ClientsSelectStore } from '@app/components/clients-select/clients-select.store';

@Component({
  selector: 'clients-select-option',
  standalone: true,
  host: {
    '[class._selected]': 'isSelected'
  },
  imports: [PuiCardModule, PuiAvatarModule, PuiTypographyModule, PuiInputCheckboxModule, FormsModule],
  templateUrl: 'clients-select-option.component.html',
  styleUrl: 'clients-select-option.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsSelectOptionComponent {
  @Input({
    required: true
  })
  client!: ClientView;

  @Input({
    required: true
  })
  isSelected!: boolean;

  private store = inject(ClientsSelectStore);

  @HostListener('click')
  onClick() {
    if (this.isSelected) {
      this.store.unselectClient(this.client);
    } else {
      this.store.selectClient(this.client);
    }
  }
}
