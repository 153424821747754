import { NotificationsService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from '@app/core/auth/services';
import { IUserMembership } from '@app/core/membership/types';
import { FloatingPopoverService } from '@app/modules/floating-popover/floating-popover.service';
import { CreateTeamModalComponent } from '@app/modules/workspaces/components/create-team-modal/create-team-modal.component';
import { WorkspacesServerStoreService } from '@app/modules/workspaces/services/workspaces-server-store.service';
import {
  IWorkspace,
  IWorkspaceClient,
  IWorkspaceInvitationInfo,
  IWorkspaceMember,
  IWorkspaceSettingsData,
  WorkspaceUser
} from '@app/modules/workspaces/types';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { SalesStatsType } from '@app/screens/guide/guide-workspaces/stats/types';
import { RevenueStatsType } from '@app/screens/guide/guide-workspaces/stats/types/revenue-stats.type';
import { API_PATH } from '@app/shared/constants/endpoints';
import { ServiceGuide } from '@app/shared/interfaces/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { BrandingService } from '@app/core/branding/branding.service';

@Injectable({ providedIn: 'root' })
export class WorkspacesService {
  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeWorkspace$: BehaviorSubject<IWorkspace> = new BehaviorSubject<IWorkspace>(undefined);

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private _workspaces$: ReplaySubject<IWorkspace[]> = new ReplaySubject<IWorkspace[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _members$: BehaviorSubject<IWorkspaceMember[]> = new BehaviorSubject<IWorkspaceMember[]>([]);

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private _clients$: ReplaySubject<IWorkspaceClient[]> = new ReplaySubject<IWorkspaceClient[]>();

  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _nextWorkspace$: BehaviorSubject<IWorkspace> = new BehaviorSubject<IWorkspace>(undefined);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get activeWorkspace$() {
    return this._activeWorkspace$.asObservable();
  }

  get workspaces$(): Observable<IWorkspace[]> {
    return this._workspaces$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get members$() {
    return this._members$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clients$() {
    return this._clients$.asObservable();
  }

  get isAdmin$(): Observable<boolean> {
    return this._activeWorkspace$.pipe(
      filter((ws: IWorkspace) => ws !== undefined),
      map((workspace: IWorkspace) => WorkspaceUtility.isAdmin(workspace))
    );
  }

  get isTeamAdmin$(): Observable<boolean> {
    return combineLatest([this.isAdmin$, this.isTeam$]).pipe(map(([isAdmin, isTeam]) => isAdmin && isTeam));
  }

  get isSolo$(): Observable<boolean> {
    return this.activeWorkspace$.pipe(map((workspace: IWorkspace) => WorkspaceUtility.isSolo(workspace)));
  }

  get isTeam$(): Observable<boolean> {
    return this.activeWorkspace$.pipe(
      filter((ws: IWorkspace) => ws !== undefined),
      map((workspace: IWorkspace) => WorkspaceUtility.isTeam(workspace))
    );
  }

  get workspace(): IWorkspace {
    return this._activeWorkspace$.getValue();
  }

  get isAdmin(): boolean {
    const workspace: IWorkspace = this._activeWorkspace$.getValue();
    return WorkspaceUtility.isAdmin(workspace);
  }

  get isSolo(): boolean {
    const workspace: IWorkspace = this._activeWorkspace$.getValue();
    return WorkspaceUtility.isSolo(workspace);
  }

  get isTeam(): boolean {
    const workspace: IWorkspace = this._activeWorkspace$.getValue();
    return WorkspaceUtility.isTeam(workspace);
  }

  get isTeamAdmin(): boolean {
    return this.isTeam && this.isAdmin;
  }

  get nextWorkspace$(): Observable<IWorkspace> {
    return this._nextWorkspace$.asObservable();
  }

  get members(): IWorkspaceMember[] {
    return this._members$.getValue();
  }

  constructor(
    private readonly _authService: AuthService,
    private readonly _httpClient: HttpClient,
    private readonly _notifications: NotificationsService,
    private readonly _store: WorkspacesServerStoreService,
    private readonly _floatingPopover: FloatingPopoverService,
    private readonly _bottomSheetRef: MatBottomSheetRef,
    private readonly _brandingService: BrandingService,
    private _modalService: NgbModal,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    const isBrowser = isPlatformBrowser(platformId);

    if (isBrowser) {
      // TODO By some reason WorkspacesService and AnalyticsService has circular dependencies.
      this.activeWorkspace$.pipe(filter(Boolean)).subscribe((workspace: IWorkspace) => {
        localStorage.setItem('ws_type', `${workspace.type}`);
        localStorage.setItem('ws_id', `${workspace.id}`);
        localStorage.setItem('ws_name', `${workspace.name}`);
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getWorkspaces() {
    return this._store
      .getWorkspaces$()
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        take(1)
      )
      .subscribe((workspaces: IWorkspace[]) => {
        this._workspaces$.next(workspaces);
      });
  }

  getInfo(workspaceId: number): Observable<IWorkspaceSettingsData> {
    return this._store.getInfo$(workspaceId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getMembers() {
    return this._store
      .getMembers$()
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        take(1)
      )
      .subscribe((members: IWorkspaceMember[]) => {
        this._members$.next(members);
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getClients() {
    return this._store
      .getClients$()
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        take(1)
      )
      .subscribe((clients: IWorkspaceClient[]) => {
        this._clients$.next(clients);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createWorkspace(name: string, type: string): Observable<any> {
    return this._store.createWorkspace$(name, type).pipe(
      catchError(error => {
        this._notifications.error(`Team was not created.`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getActiveWorkspace() {
    return this._store
      .getActiveWorkspace$()
      .pipe(
        catchError(error => throwError(error)),
        take(1)
      )
      .subscribe(({ workspace }: { workspace: IWorkspace }) => {
        this._activeWorkspace$.next(workspace);
        // @ts-expect-error TS2345
        this._nextWorkspace$.next(null);
      });
  }

  sendInvitations(invitationsInfo: IWorkspaceInvitationInfo): Observable<void> {
    return this._store.sendInvitations$(invitationsInfo).pipe(
      catchError(error => {
        this._notifications.error(`Invitation was not send.`);
        return throwError(error);
      }),
      tap(() => {
        this.getMembers();
      })
    );
  }

  getWorkspaceUsers(): Observable<WorkspaceUser[]> {
    return this._store.getWorkspaceUsers$();
  }

  getGuidesByWorkspaceId$(workspaceId: number): Observable<ServiceGuide[]> {
    return this._store
      .getGuidesByWorkspaceId$(workspaceId)
      .pipe(map(guides => guides.map(guide => new ServiceGuide(guide))));
  }

  getSalesStats(workspaceId: number): Observable<SalesStatsType> {
    return this._store.getSalesStats$(workspaceId);
  }

  getRevenueStats(workspaceId: number): Observable<RevenueStatsType> {
    return this._store.getRevenueStats$(workspaceId);
  }

  setNextWorkspace$(workspaceId: number): Observable<IWorkspace[]> {
    return this.workspaces$.pipe(
      take(1),
      tap(workspaces => {
        const nextWorkspace = workspaces.find(ws => ws.id === workspaceId);
        // @ts-expect-error TS2345
        this._nextWorkspace$.next(nextWorkspace);
        // @ts-expect-error TS2345
        this._activeWorkspace$.next(nextWorkspace);
      })
    );
  }

  switchWorkspace$(
    workspaceId: number,
    invitationCode?: string
  ): Observable<{ done: boolean; token?: string; subscription?: IUserMembership; reason?: string }> {
    return this._httpClient
      .post<{ done: boolean; token?: string; subscription?: IUserMembership; reason?: string }>(
        `${API_PATH}/user/guide/workspaces/active`,
        { workspaceId, invitationCode }
      )
      .pipe(
        catchError(error => throwError(error)),
        tap(response => {
          if (!response.done) {
            return;
          }

          const { token, subscription } = response;

          // @ts-expect-error TS2345
          this._authService.changeToken(token);
          this._authService.setUserMembership(subscription);
          this._brandingService.loadBranding();
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  closeWorkspaceMenu() {
    this._floatingPopover.closeModalSource.next();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._bottomSheetRef.dismiss && this._bottomSheetRef.dismiss();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createTeamWorkspace() {
    this._modalService.open(CreateTeamModalComponent, { backdrop: 'static' });
    this.closeWorkspaceMenu();
  }
}
