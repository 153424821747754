import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnInit
} from '@angular/core';
import { PuiAvatarModule, PuiCardModule, PuiInputCheckboxModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { FormsModule } from '@angular/forms';
import { ClientsSelectStore } from '@app/components/clients-select/clients-select.store';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'clients-select-all-option',
  standalone: true,
  templateUrl: 'clients-select-all-option.component.html',
  imports: [PuiCardModule, PuiAvatarModule, PuiTypographyModule, PuiInputCheckboxModule, FormsModule, AsyncPipe],
  styleUrl: '../clients-select-option/clients-select-option.component.scss',
  host: {
    '[class._selected]': 'isAllSelected'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsSelectAllOptionComponent implements OnInit {
  protected store = inject(ClientsSelectStore);
  protected isAllSelected!: boolean;

  private cdRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.store.isAllSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isAllSelected => {
      this.isAllSelected = isAllSelected;
      this.cdRef.markForCheck();
    });
  }

  @HostListener('click')
  onClick() {
    if (this.isAllSelected) {
      this.store.unselectAll();
    } else {
      this.store.selectAll();
    }
  }
}
