import { produce } from 'immer';
// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticCreateSourceTypes, AnalyticServiceTypes, InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { CurrencyService } from '@app/core/currency/currency.service';
import { FormService } from '@app/core/form/form.service';
import { RETURN_AFTER_SAVE_PARAM } from '@app/modules/new-service-button/components/service-selector/service-selector.component';
import { QuillEditorImageComponent } from '@app/modules/quill-editor-image/quill-editor-image.component';
import { UpdateValueAndValidityService } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.service';
import { BasicInfoFormAppearanceName } from '@app/modules/session-forms/forms/basic-info-form';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { SessionTemplateEditorService } from '@app/screens/guide/guide-sessions-templates/services/session-template-editor.service';
import { SessionTemplatePermissionsService } from '@app/screens/guide/guide-sessions-templates/services/session-template-permissions.service';
import { SessionTemplatePreviewService } from '@app/screens/guide/guide-sessions-templates/services/session-template-preview.service';
import { SessionTemplateServerStoreService } from '@app/screens/guide/guide-sessions-templates/services/session-template-server-store.service';
import {
  ICanDeactivate,
  ServiceAssigneePermission,
  SessionTemplate
} from '@app/screens/guide/guide-sessions-templates/types';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { PUBLIC_SESSION_TEMPLATE_PROVIDERS } from '@app/screens/session-templates/services/public-session-template.providers';
import { PublicSessionsTemplatesApi } from '@app/screens/session-templates/services/public-sessions-templates-api.service';
import { ScrollToFirstInvalidDirective } from '@app/shared/directives/scroll-to-first-invalid.directive';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { PUI_IS_MOBILE, PuiDestroyService, PuiDialogService } from '@awarenow/profi-ui-core';

import { menuItems } from './menu-items';
import { SessionTemplateForm } from './types';
import { createForm, createSessionTemplateFormValue, createSessionTemplateInterfaceFromFromValue } from './utils';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

@Component({
  selector: 'app-session-template-settings',
  templateUrl: './session-template-settings.component.html',
  styleUrls: ['./session-template-settings.component.scss'],
  providers: [
    forwardRef(() => SessionTemplatePreviewService),
    PUBLIC_SESSION_TEMPLATE_PROVIDERS,
    PublicSessionsTemplatesApi,
    SessionTemplateServerStoreService,
    SessionTemplatePermissionsService,
    PuiDestroyService,
    UpdateValueAndValidityService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTemplateSettingsComponent implements OnInit, ICanDeactivate {
  readonly menuItems = menuItems;
  private editorCanBeClosed = false;
  UserRoles = UserRoles;

  form = createForm(this.fb);

  formSpanShot!: SessionTemplateForm;

  @ViewChild('descriptionEditor', { static: false })
  descriptionEditor!: QuillEditorImageComponent;

  @ViewChild(ScrollToFirstInvalidDirective, { static: false })
  invalidContentElementScroll!: ScrollToFirstInvalidDirective;

  get canDeactivate(): boolean {
    return this.editorCanBeClosed || !this.editor.originalTemplate?.id || this.isFormValid();
  }

  get serviceName(): string {
    return this.form.get('basicInfo')?.value?.name;
  }

  get isTeamAdmin(): boolean {
    return this.workspacesService.isTeamAdmin;
  }

  get isPlatformAdmin(): boolean {
    return this.auth.user.RoleId === UserRoles.ADMIN;
  }

  get basicInfoAppearance(): BasicInfoFormAppearanceName {
    if (this.workspacesService.isSolo || this.auth.isPlatformAdmin()) {
      return BasicInfoFormAppearanceName.SOLO_WORKSPACE;
    } else if (this.workspacesService.isTeamAdmin) {
      return BasicInfoFormAppearanceName.TEAM_WORKSPACE_OWNER;
    } else {
      return BasicInfoFormAppearanceName.TEAM_WORKSPACE_PROVIDER;
    }
  }

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly browserWindow: Window,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>,
    readonly auth: AuthService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly destroy$: PuiDestroyService,
    private readonly editor: SessionTemplateEditorService,
    private readonly fb: UntypedFormBuilder,
    private readonly formUtils: FormService,
    private readonly sessionTemplatePermissionsService: SessionTemplatePermissionsService,
    private readonly sessionTemplatePreview: SessionTemplatePreviewService,
    private readonly workspacesService: WorkspacesService,
    private readonly updateNestedForms: UpdateValueAndValidityService,
    private dialog: PuiDialogService,
    readonly currency: CurrencyService
  ) {}

  ngOnInit(): void {
    this.editor.template$.pipe(takeUntil(this.destroy$)).subscribe(sessionTemplate => {
      const sessionTemplateFormValue: SessionTemplateForm = createSessionTemplateFormValue(sessionTemplate);
      this.form.setValue(sessionTemplateFormValue);

      this.formSpanShot = this.form.getRawValue();

      this.sessionTemplatePermissionsService.applyPermissions(this.form, sessionTemplate.permission);
    });

    this.setSubscriptions();
  }

  preview(): void {
    if (!this.isFormValid()) {
      return;
    }

    this.sessionTemplatePreview.preview(this.form.getRawValue());
  }

  saveChanges(): void {
    if (!this.isFormValid()) {
      return;
    }

    if (this.didFormChange()) {
      const rawSessionTemplateFormValue: SessionTemplateForm = this.form.getRawValue() as SessionTemplateForm;

      let sessionTemplateFormValue: SessionTemplateForm = rawSessionTemplateFormValue;
      /*
      [Provider permission] can only change the Location field
       */
      if (this.editor.originalTemplate?.permission === ServiceAssigneePermission.PROVIDER) {
        sessionTemplateFormValue = produce(this.formSpanShot, draft => {
          draft.basicInfo.location = rawSessionTemplateFormValue.basicInfo.location;
        });
      }
      const rawSessionTemplate = createSessionTemplateInterfaceFromFromValue(
        sessionTemplateFormValue,
        this.editor.originalTemplate,
        this.workspacesService.isSolo,
        !this.workspacesService.isTeamAdmin
      );

      const newSessionTemplate = new SessionTemplate(SessionTemplate.clean(rawSessionTemplate));

      let saveTemplate$: Observable<{ id: number; isCreating: boolean }>;

      if (this.isPlatformAdmin) {
        /**
         * It necessary to show message before save.
         * https://profi-io.atlassian.net/browse/PR-5734
         */
        const ref = this.dialog.open(GuideChangesForNewUsersDialogComponent);

        saveTemplate$ = ref.afterClosed$.pipe(
          filter((res: RedirectAfterSaveActions) => res === RedirectAfterSaveActions.SAVE),
          switchMap(() => this.editor.saveTemplate$(newSessionTemplate))
        );
      } else {
        saveTemplate$ = this.editor.saveTemplate$(newSessionTemplate);
      }

      saveTemplate$.pipe(takeUntil(this.destroy$)).subscribe(({ isCreating }) => {
        this.editorCanBeClosed = true;

        if (isCreating) {
          // ToDo rework it and delete analyticsService from component
          const analyticsData = {
            autoConfirm: newSessionTemplate.hasAutoConfirmation,
            createSource: AnalyticCreateSourceTypes.SERVICE_LIST,
            servicePrice: newSessionTemplate.price,
            sharing: newSessionTemplate.status,
            serviceType: AnalyticServiceTypes.GROUP_SESSION,
            chat: false,
            platform:
              newSessionTemplate.location.connectionType === SessionConnectionTypes.IN_PLATFORM
                ? 'profi'
                : newSessionTemplate.location.connectionType
          };

          this.analyticsService.event(InternalEvents.SERVICE_CREATE, analyticsData);
        }

        const { [RETURN_AFTER_SAVE_PARAM]: shouldReturnBack = false } = this.route.snapshot.queryParams;

        if (shouldReturnBack) {
          this.locationBack();
        } else {
          this.editor.close();
        }
      });
    } else {
      this.editor.close();
    }
  }

  scrollTo(menuItemId: string): void {
    const el = this.browserWindow.document.querySelector(`#${menuItemId}`) as HTMLElement;

    el.scrollIntoView({ behavior: 'smooth' });
  }

  private locationBack(): void {
    this.location.back();
  }

  private isFormValid(): boolean {
    if (this.formUtils.markInvalidForm(this.form)) {
      this.invalidContentElementScroll.scroll();
      this.updateNestedForms.next();
      return false;
    }

    return true;
  }

  private didFormChange(): boolean {
    const currentFormState = this.form.getRawValue();
    const prevFormState = this.formSpanShot;

    return !isEqual(currentFormState, prevFormState);
  }

  private setSubscriptions(): void {
    this.form
      ?.get('basicInfo')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(({ name }: { name: string }) => this.editor.updateName(name));
  }
}
