import { FieldPolicy } from '@apollo/client/cache/inmemory/policies';

export const getOfferPolicy: {
  [fieldName: string]: FieldPolicy;
} = {
  getOffer: {
    keyArgs: ['input', 'id'],
    read(existing, { args, toReference }) {
      return existing || toReference({ __typename: 'Offer', id: args?.input?.id });
    }
  }
};
