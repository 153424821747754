<form [formGroup]="form">
  <div class="pui-space_bottom-4">
    <div>
      <h3
        *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.FORM_HEADER_TITLE"
        pui-typography="h3"
        i18n="@@sessionTemplateRestrictionsTitle">
        Restrictions
      </h3>
      <div
        pui-typography="alert"
        class="restrictions-form__explanation"
        i18n="@@sessionTemplateRestrictionsExplanation">
        Set up booking restrictions for your clients.
      </div>
    </div>
    <!--Show back button only for drawer-->
    <div class="pui-space_top-2" *ngIf="(isMobile$ | async) && !!drawerRef">
      <button (click)="close()" pui-link type="button">
        <pui-icon svgIcon="pui:menu-arrow-left" size="xs"></pui-icon>
        <span>Back</span>
      </button>
    </div>
  </div>

  <div class="restrictions-form__row pui-space_bottom-6">
    <div *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.BEFORE_EVENT_BUFFER">
      <h5 pui-typography="h5" i18n="@@BufferTimeBeforeSession">Buffer time before session</h5>
      <pui-select
        [maxHeight]="300"
        class="pui-space_top-1"
        formControlName="beforeEventBuffer"
        data-qa-id="guide-service-editor-before-event-buffer-form">
        <pui-option *ngFor="let option of bufferOptions" [value]="option.value" [label]="option.label"></pui-option>
      </pui-select>
    </div>

    <div
      *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.AFTER_EVENT_BUFFER"
      [class.pui-space_top-6]="isMobile$ | async">
      <h5 pui-typography="h5" i18n="@@BufferTimeAfterSession">Buffer time after session</h5>
      <pui-select
        [maxHeight]="300"
        class="pui-space_top-1"
        formControlName="afterEventBuffer"
        data-qa-id="guide-service-editor-after-event-buffer-form">
        <pui-option *ngFor="let option of bufferOptions" [value]="option.value" [label]="option.label"></pui-option>
      </pui-select>
    </div>
  </div>

  <div class="restrictions-form__row pui-space_bottom-6">
    <div *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.MINIMUM_BOOKING_NOTICE">
      <h5 pui-typography="h5" i18n="@@MinimumNotice">Minimum notice</h5>
      <div class="d-flex flex-row">
        <pui-input-field class="pui-space_right-2">
          <input
            formControlName="minimumBookingNotice"
            data-qa-id="guide-service-editor-min-booking-notice-form"
            updateValueAndValidity
            pui-input-text
            type="text"
            [maskito]="{ mask: minimumNoticeMask, guide: false }" />
        </pui-input-field>
        <pui-select
          class="restrictions-form__minimum-notice"
          [maxHeight]="300"
          [formControl]="minimumNoticeMeasureControl">
          <pui-option
            *ngFor="let option of minimumNoticeOptions"
            [value]="option.value"
            [label]="option.label"></pui-option>
        </pui-select>
      </div>
      <pui-input-error [formControl]="form.controls.minimumBookingNotice" role="alert"></pui-input-error>
    </div>

    <div>
      <ng-container *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.SLOT_INTERVAL">
        <h5 pui-typography="h5" i18n="@@TimeSlotIntervals">Time-slot intervals</h5>
        <div class="restrictions-form__time-slots" pui-group [collapsed]="true">
          <pui-input-field>
            <input
              formControlName="slotInterval"
              data-qa-id="guide-service-editor-slot-interval-form"
              pui-input-text
              type="text"
              [maskito]="{ mask: timeSlotMask, guide: false }"
              min="15"
              max="120" />
          </pui-input-field>
          <button [disabled]="true" pui-button i18n="@@min" data-qa-id="guide-service-editor-slot-interval-min">
            min
          </button>
        </div>
        <pui-input-error [formControl]="form.controls.slotInterval" role="alert"></pui-input-error>
      </ng-container>
    </div>
  </div>
  <!-- Limit booking frequency -->
  <div
    *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.LIMIT_BOOKING_FREQUENCY"
    class="d-flex flex-row pui-space_bottom-4"
    data-qa-id="guide-service-editor-limit-booking-frequency">
    <app-limit-booking-frequency formControlName="limitBookingFrequency"></app-limit-booking-frequency>
  </div>
  <!-- Limit future bookings -->
  <div
    *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.LIMIT_FUTURE_BOOKINGS"
    class="d-flex flex-row pui-space_bottom-4"
    data-qa-id="guide-service-editor-limit-future-bookings-form">
    <pui-toggle
      class="restrictions-form__toggle pui-space_bottom-2"
      [checked]="!!form.controls.limitFutureBookings?.value"
      [disabled]="form.controls.limitFutureBookings?.disabled"
      #enableLimitFutureBookings
      (change)="limitFutureBookingToggle($event.checked)"></pui-toggle>
    <div class="d-flex flex-column w-100 toggle-description">
      <div class="d-flex flex-column pui-space_bottom-2">
        <span pui-typography="body-s-bold" i18n="@@LimitFutureBookings">Limit future bookings</span>
        <span
          class="restrictions-form__toggle-description"
          pui-typography="body-s"
          i18n="@@LimitHowFutureBookingsDescription"
          >Limit how far in the future this event can be booked.</span
        >
      </div>

      <div
        *ngIf="enableLimitFutureBookings.checked"
        [formGroup]="limitFutureBookingsFormGroup"
        class="restrictions-form__frequency-col pui-space_bottom-2">
        <div class="restrictions-form__frequency-row align-items-center">
          <label
            class="align-items-center pui-space_bottom-2"
            pui-input-radio
            formControlName="periodType"
            [value]="periodType.ROLLING"
            (ngModelChange)="togglePeriodType()">
          </label>
          <pui-input-field class="restrictions-form__future-limit">
            <input
              formControlName="periodDays"
              updateValueAndValidity
              pui-input-text
              [maskito]="{ mask: limitsFutureMask, guide: false }" />
          </pui-input-field>
          <pui-select [maxHeight]="300" class="restrictions-form__frequency-cycle" formControlName="periodDaysType">
            <pui-option
              *ngFor="let option of futureFrequencyOptions"
              [value]="option.value"
              [label]="option.label"></pui-option>
          </pui-select>

          <span
            class="restrictions-form__frequency-text"
            data-qa-id="guide-service-editor-limit-future-bookings-text1"
            i18n="@@intoTheFuture"
            >into the future</span
          >
        </div>
        <pui-input-error
          class="pui-space_bottom-2"
          [formControl]="limitFutureBookingsFormGroup.get('periodDays')"
          role="alert"></pui-input-error>
        <div class="restrictions-form__frequency-row align-items-center">
          <label
            class="align-items-center"
            pui-input-radio
            formControlName="periodType"
            [value]="periodType.RANGE"
            (ngModelChange)="togglePeriodType()">
          </label>
          <span
            class="restrictions-form__frequency-text"
            data-qa-id="guide-service-editor-limit-future-bookings-text2"
            i18n="@@WithinADateRange"
            >Within a date range</span
          >
          <pui-input-date
            formControlName="periodStartDate"
            data-qa-id="guide-service-editor-limit-future-bookings-period-start"></pui-input-date>
          <pui-input-date
            formControlName="periodEndDate"
            data-qa-id="guide-service-editor-limit-future-bookings-period-end"
            [puiDatepickerMinDate]="limitFutureBookingsFormGroup.get('periodStartDate')?.value"></pui-input-date>
        </div>
      </div>
    </div>
  </div>
  <!-- Expiration period -->
  <div
    *ngIf="appearance | restrictionsFormAppearance: RestrictionsFormFields.EXPIRATION_PERIOD"
    class="d-flex flex-row pui-space_bottom-4">
    <pui-toggle
      class="restrictions-form__toggle pui-space_bottom-2"
      [checked]="!!form.controls.expirationPeriod?.value"
      [disabled]="form.controls.expirationPeriod?.disabled"
      #enableExpirationPeriod
      (change)="expirationPeriodToggle($event.checked)"></pui-toggle>
    <div class="d-flex flex-column w-100">
      <div class="d-flex flex-column pui-space_bottom-2">
        <span pui-typography="body-s-bold" i18n="@@setExpirationPeriod">Set expiration period</span>
        <span
          class="restrictions-form__toggle-description"
          pui-typography="body-s"
          i18n="@@setExpirationPeriodDescriptionText"
          >Allow booking sessions for a time within this period after purchase.</span
        >
      </div>

      <div
        *ngIf="enableExpirationPeriod.checked"
        [formGroup]="expirationPeriodForm"
        class="restrictions-form__expiration_period pui-space_bottom-2">
        <h5 pui-typography="h5" i18n="@@shouldBeUsedWithinDescription">Sessions should be used within</h5>

        <div class="restrictions-form__expiration_period-row align-items-center">
          <pui-input-field class="restrictions-form__expiration_period_count pui-space_top-1">
            <input
              formControlName="count"
              updateValueAndValidity
              pui-input-text
              type="text"
              [maskito]="{ mask: expirationMask, guide: false }" />
          </pui-input-field>
          <pui-select
            [maxHeight]="300"
            class="restrictions-form__expiration_period_type pui-space_top-1 flex-grow-1"
            formControlName="type">
            <pui-option [value]="'month'" [label]="'month'"></pui-option>
            <pui-option [value]="'year'" [label]="'year'"></pui-option>
          </pui-select>
        </div>
        <pui-input-error [formControl]="expirationPeriodForm.controls.count" role="alert"></pui-input-error>
      </div>
    </div>
  </div>
</form>
