<form updateValueAndValidity [formGroup]="form">
  <div class="pui-space_bottom-8">
    <div
      *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.FORM_HEADER_TITLE"
      class="pui-space_bottom-4">
      <h3 pui-typography="h3" i18n="@@sessionTemplateBasicInfoTitle">Basic info</h3>
    </div>
    <div class="pui-row">
      <!-- Session title -->
      <div
        *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.SESSION_TITLE"
        class="pui-space_bottom-5 flex-grow-1">
        <label pui-input-label for="session_template_title" transloco="sessionTemplateTitle" [required]="true">
          Session title
        </label>
        <pui-input-field>
          <input
            formControlName="name"
            id="session_template_title"
            pui-input-text
            type="text"
            data-qa-id="session-template-settings__title" />
          <pui-input-error [formControl]="form.get('name')" role="alert"></pui-input-error>
        </pui-input-field>
      </div>
      <!-- Count -->
      <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.COUNT" class="pui-space_bottom-5">
        <label pui-input-label for="session_template_count" i18n="@@sessionTemplateCountLabel">Total sessions</label>
        <div
          class="basic-info-form__count"
          *puiLet="form.get('count').disabled as countDisabled"
          pui-group
          [collapsed]="true">
          <button
            [disabled]="countDisabled"
            pui-button
            appearance="secondary"
            (click)="form.get('count').setValue(+form.value.count <= minTotalCount ? 1 : +form.value.count - 1)">
            <span>-</span>
          </button>
          <pui-input-field>
            <input
              data-qa-id="session-template-settings__count"
              formControlName="count"
              min="1"
              step="1"
              pui-input-text
              [maskito]="{ mask: totalCountMask, guide: false }"
              type="text" />
          </pui-input-field>
          <button
            [disabled]="countDisabled"
            pui-button
            appearance="secondary"
            (click)="
              form
                .get('count')
                .setValue(+form.value.count === maxTotalCount ? +form.value.count : +form.value.count + 1)
            ">
            +
          </button>
        </div>
      </div>
    </div>
    <!-- Session description -->
    <div
      *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.SESSION_DESCRIPTION"
      class="pui-space_bottom-5">
      <label pui-input-label for="session_template_description" i18n="@@sessionTemplateDescriptionLabel">
        Description
      </label>
      <app-text-editor-image
        #descriptionEditor
        class="description-editor"
        id="template_description"
        qaId="session-template-settings__description"
        formControlName="descriptionRepresentation"
        placeholder=""
        format="object"
        [extendedVideoUploader]="true"></app-text-editor-image>
    </div>
    <!-- Upload cover image -->
    <div
      *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.UPLOAD_COVER_IMAGE"
      class="pui-space_bottom-5">
      <app-cover-image-wrapper
        recommendedDimensionsText="1200 x 440px"
        i18n-label="@@coverImage"
        label="Cover image"
        [coverVariantsSettings]="coverVariantsSettings"
        [backgroundUrl]="form.controls.coverImage?.value"
        [disabled]="coverImageDisabled"
        (uploadImage)="uploadImage($event)"
        (removeImage)="removeImage()"
        imagePrefix="session">
        <input type="hidden" name="coverImageUrl" formControlName="coverImage" />
        <input type="hidden" name="coverImageThumbUrl" formControlName="coverImageThumb" />

        <button pui-link>
          <pui-icon class="pui-space_right-1" svgIcon="pui:upload" size="s"></pui-icon>
          <span i18n="@@UploadCoverImage">Upload cover image</span>
        </button>
      </app-cover-image-wrapper>
    </div>
    <!-- Location -->
    <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.LOCATION" class="pui-space_bottom-5">
      <app-location-form formControlName="location"></app-location-form>
    </div>
    <!-- Duration -->
    <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.DURATION" class="pui-space_bottom-5">
      <label pui-input-label for="session_template_duration" i18n="@@sessionTemplateDurationLabel" [required]="true"
        >Duration</label
      >
      <div class="basic-info-form__duration" pui-group [collapsed]="true">
        <pui-input-field>
          <input
            data-qa-id="session-template-settings__duration"
            formControlName="length"
            [maskito]="{ mask: durationMask, guide: false }"
            min="1"
            step="1"
            pui-input-text
            type="text" />
        </pui-input-field>
        <button
          class="disabled-button"
          data-qa-id="session-template-settings__duration_min"
          [disabled]="true"
          pui-button
          i18n="@@min">
          min
        </button>
      </div>
      <pui-input-error [formControl]="form.get('length')" role="alert"></pui-input-error>
    </div>
    <!-- Recurrence -->
    <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.RECURRENCE" class="pui-space_bottom-5">
      <app-recurrence-form
        formControlName="recurring"
        data-qa-id="guide-service-editor-recurrence-form"></app-recurrence-form>
    </div>
    <!-- Status -->
    <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.STATUS" class="pui-space_bottom-5">
      <pui-toggle class="pui-space_bottom-4" formControlName="status" data-qa-id="guide-service-editor-status-form">
        <div class="d-flex flex-column">
          <span pui-typography="body-s-bold" i18n="@@showSessionTitle">Show session on the booking page</span>
          <span class="basic-info-form__description-text" pui-typography="body-s" i18n="@@ShowSessionAllowDescription"
            >Allow clients to view and book the session from your booking page.
          </span>

          <ng-container *ngIf="!form.controls.status.value">
            <div class="basic-info-form__restrictions">
              <pui-input-checkbox
                formControlName="restrictClientBooking"
                data-qa-id="guide-service-editor-booking-restrictions-form"
                i18n="@@sessionTemplateSettingsRestrictClientBooking">
                Restrict clients from booking. Only host or admin can schedule this session for clients.
              </pui-input-checkbox>
            </div>
          </ng-container>
        </div>
      </pui-toggle>
    </div>

    <div *basicInfoFormAppearance="appearance; fieldName: basicInfoFormFields.RESTRICT_CLIENT_BOOKING">
      <pui-toggle formControlName="restrictClientBooking">
        <div class="d-flex flex-column pui-space_bottom-2">
          <span pui-typography="body-s-bold" i18n="@@RestrictClientsFromBooking">Restrict clients from booking</span>
          <span
            class="recurrence-form__description-text"
            pui-typography="body-s"
            i18n="@@OnlyAdminCanScheduleThisSession"
            >Only host or admin can schedule this session for clients.</span
          >
        </div>
      </pui-toggle>
    </div>
  </div>
</form>
