import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { IUser } from '@app/shared/interfaces/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadChatImageModalComponent } from '@app/screens/chat/components/upload-image-modal/upload-chat-image-modal.component';
import { FileStackService } from '@app/core/filestack/filestack.service';
import { FileStackFile } from '@app/shared/interfaces/filestack';
import { IAttachment } from '@app/core/chat/types';
import { VideoUploaderComponent } from '@app/modules/quill-editor-image/video-uploader/video-uploader.component';
import { ActiveChatService } from '@app/core/chat/active-chat.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

enum FileTypes {
  Image = 1,
  Video = 2,
  Other = 3
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-chat-message-box',
  templateUrl: './chat-message-box.component.html',
  styleUrls: ['./chat-message-box.component.scss']
})
export class ChatMessageBoxComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('qlEditor') qlEditorElement: any;

  @Input()
  // @ts-expect-error TS2564
  user: IUser;

  @Input()
  // @ts-expect-error TS2564
  bot: boolean;

  @Output()
  sendMessage = new EventEmitter<{ message: string; attachment?: IAttachment }>();

  message = '';

  // @ts-expect-error TS2564
  isEmojiPickerVisible: boolean;

  // @ts-expect-error TS2564
  attachment: { file?: IAttachment; loaded: boolean; loadedPercent: number };

  // @ts-expect-error TS2564
  fileType: FileTypes;

  readonly FileTypes = FileTypes;

  quillModules = {
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => this.emitSendMessage()
        }
      }
    }
  };

  isAttachmentExpand = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  quill: any;

  constructor(
    private modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _fileStackService: FileStackService,
    private _chat: ActiveChatService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get sendMessageDisabled(): boolean {
    return (!this.message || !this.message.trim()) && (!this.attachment || !this.attachment.file);
  }

  emitSendMessage(): void {
    if (!this.sendMessageDisabled) {
      this.sendMessage.emit({
        message: (this.message && this.message.trim()) || '',
        attachment: this.attachment && this.attachment.file
      });
      this.message = '';
      // @ts-expect-error TS2322
      this.attachment = null;
    }
  }

  emitUploadFile(event: Event): void {
    this.hideAttachmentPopup();
    const { files } = event.target as HTMLInputElement;
    this._chat
      // @ts-expect-error TS2531
      .uploadFile(files[0])
      .pipe(takeUntil(this.destroy$))
      .subscribe(attachment => {
        // @ts-expect-error TS2322
        this.attachment = attachment;
        // @ts-expect-error TS2322
        this.fileType = attachment && attachment.file ? this.getFileType(attachment.file) : null;
        if (this.quill) {
          this.quill.focus();
        }
      });

    // @ts-expect-error TS2322
    (event.target as HTMLInputElement).value = null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  deleteFile() {
    this.destroy$.next();
    // @ts-expect-error TS2322
    this.attachment = null;
    // @ts-expect-error TS2322
    this.fileType = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEmojiSelected(event: any): void {
    this.message = `${this.message || ''}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  toggleEmojiPicker(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  onClickedOutsideEmoji(): void {
    this.isEmojiPickerVisible = false;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setQuill(quill) {
    this.quill = quill;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openUploadImageModal() {
    const { result } = this.modal.open(UploadChatImageModalComponent, { centered: true });
    result
      .then(message => {
        this.sendMessage.emit(message);
      })
      .catch(() => {});
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleAttachmentExpand() {
    this.isAttachmentExpand = !this.isAttachmentExpand;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hideAttachmentPopup() {
    this.isAttachmentExpand = false;
  }

  handleFileStackEmbedding(): void {
    this.hideAttachmentPopup();
    this._fileStackService.openUploadWindow((file: FileStackFile) => {
      const url = this._runtimeConfigService.get('filestackS3Url') + file.key;
      this.attachment = { file: { url, name: file.filename, size: file.size }, loaded: true, loadedPercent: 100 };
      // @ts-expect-error TS2322
      this.fileType = this.getFileType(file);
    });
  }

  handleVideoEmbedding(): void {
    this.hideAttachmentPopup();
    const modalRef = this.modal.open(VideoUploaderComponent, { size: 'lg' });

    modalRef.result
      .then(videoUrl => {
        this.attachment = { file: { url: videoUrl, name: 'video' }, loaded: true, loadedPercent: 100 };
        this.fileType = FileTypes.Video;
      })
      .catch(() => {});
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getFileType(file: { url: string }) {
    if (!file || !file.url) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (/(\.jpg|\.jpeg|\.png)$/i.test((<IAttachment>file).url)) {
      return FileTypes.Image;
    }

    return FileTypes.Other;
  }
}
