<div class="auth-body">
  <h4 class="title" i18n="@@signInWelcomeBackTitle">Welcome back, {{ firstName }}</h4>
  <div class="auth-form">
    <form [formGroup]="signInForm" (ngSubmit)="signIn()" class="pui-form">
      <div class="pui-form__row">
        <div class="pui-form__field">
          <label pui-input-label for="email" i18n="@@email">Email</label>
          <pui-input-field>
            <input
              pui-input-text
              formControlName="email"
              id="email"
              type="email"
              readonly
              data-qa-id="sign-in__textfield-email" />
          </pui-input-field>
        </div>
      </div>

      <div class="pui-form__row">
        <div class="pui-form__field">
          <label pui-input-label for="password" i18n="@@password">Password</label>
          <pui-input-field>
            <input
              pui-input-text
              id="password"
              type="password"
              formControlName="password"
              data-qa-id="sign-in__textfield-password"
              (change)="onPasswordChanged()" />
          </pui-input-field>
        </div>
      </div>

      <app-form-control-error [control]="signInForm.controls['password']" [show]="wrongPassword">
        <ng-template [ngIf]="wrongPassword" [ngIfElse]="invalidPassword" i18n="@@wrongPassword">
          Passwords are not matching, please check and try again.
        </ng-template>
        <ng-template #invalidPassword i18n="@@invalidPassword">Invalid password.</ng-template>
      </app-form-control-error>

      <div class="auth-buttons">
        <button pui-button type="submit" i18n="@@signIn" data-qa-id="sign-in__button-submit">Sign in</button>
        <a pui-link routerLink="/auth/forgot-password" i18n="@@forgotPassword"> Forgot Password? </a>
      </div>

      <!--
        Necessary solution to mention client about privacy. See more:
        https://developers.google.com/recaptcha/docs/faq?hl=ru#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
      -->
      <div class="pui-space_top-4 text-center">
        <small pui-typography="body-s">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a target="_blank" rel="noopener" href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
      </div>
    </form>
  </div>
</div>
