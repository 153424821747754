<div class="select-client-option">
  <pui-input-checkbox [ngModel]="isSelected" readonly />
  <pui-card class="pui-padding_2">
    <pui-card-header>
      <div pui-card-header-icon>
        <pui-avatar [avatarUrl]="client.photo" [text]="client.name" />
      </div>
      <span pui-card-header-title pui-typography="body-s" class="select-client-option__title">{{ client.name }}</span>

      @if (client.email; as email) {
        <small pui-card-header-subheader pui-typography="body-s"> {{ email }} </small>
      }
    </pui-card-header>
  </pui-card>
</div>
