import { Component, Input, OnInit, Inject, PLATFORM_ID, inject, ViewContainerRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { timer } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '@env/environment';
import { AlertsConfig } from '../../services/helpers/alerts-config';

import { Alert, AlertKey } from '../../types/alert';
import { AlertsService } from '../../services/alerts.service';

// @ts-expect-error TS7005
declare let Intercom;

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('hiding', [
      state('show', style({ height: '*' })),
      state('hide', style({ height: '0px' })),
      transition('show <=> hide', animate(AlertsConfig.closeTimeout))
    ])
  ]
})
export class AlertComponent implements OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _alert: Alert;
  private isBrowser = false;

  readonly AlertKey = AlertKey;

  guideRoute = environment.guideRoute;

  @Input()
  set alert(value: Alert) {
    this._alert = value;

    if (this._alert.isClosing) {
      this.close();
    }
  }

  get alert(): Alert {
    return this._alert;
  }

  hide = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(
    private alertsService: AlertsService,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platform);
  }

  ngOnInit(): void {
    if (this.alert && !this._alert.isClosing) {
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      timer(0).subscribe(() => (this.hide = false));
    }
  }

  close(): void {
    this.hide = true;

    timer(AlertsConfig.closeTimeout).subscribe(() => {
      // this.alertsService.close(this.alert.id);
      const vcr = inject(ViewContainerRef);

      vcr.clear();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openIntercom() {
    if (this.isBrowser) {
      if (Intercom) {
        Intercom('show');
      }
    }
  }
}
