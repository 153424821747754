import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isOffer, isOfferOrPending } from '@app/shared/enums/session-statuses';
import {
  PrivateSession,
  PrivateSessionsService
} from '@appWidget/modules/booking-cancellation/services/private-sessions.service';
import { SelectedPrivateSessionStore } from '@appWidget/modules/booking-cancellation/store/selected-private-session-store';
import { BookingCancellationConfirmationState } from '@appWidget/modules/booking-cancellation/types';
import { BookingNavigationService } from '@appWidget/modules/booking-root/service/booking-navigation.service';
import { Bookings } from '@appWidget/modules/booking/interfaces';
import { CancellationType } from '@appWidget/modules/booking/screens/cancellation/types';
import { PuiDestroyService, PuiDialogRef } from '@awarenow/profi-ui-core';
import { ActivatedRoute, Router } from '@angular/router';
import { convertQueryParamsToString } from '@appWidget/modules/booking/utils/convert-queryparams-to-string';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SessionCanceledEvent } from '@app/core/analytics/events/session-canceled.event';

@Component({
  selector: 'app-booking-cancellation-details',
  templateUrl: './booking-cancellation-details.component.html',
  styleUrls: ['./booking-cancellation-details.component.scss', '../../common/styles/layout.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingCancellationDetailsComponent {
  readonly CancellationType = CancellationType;
  readonly session$ = this.privateSessionStore.privateSession$;
  readonly bookings$: Observable<Bookings[]> = this.privateSessionStore.sessionEvents$;
  readonly bookingsSelected$: Observable<Bookings[]>;
  readonly isSending$ = new BehaviorSubject(false);
  state: { [key: string]: unknown } | undefined;

  readonly reasonLabel$ = this.session$.pipe(
    map(session =>
      isOfferOrPending(session?.status!)
        ? `Reason for declining the request (optional)`
        : `Reason for cancellation (optional)`
    )
  );
  readonly reasonPlaceholder$ = this.session$.pipe(
    map(session =>
      isOfferOrPending(session?.status!)
        ? translocoService.translate('bookingDecliningClientReasonPlaceholder')
        : translocoService.translate('bookingCancellationClientReasonPlaceholder')
    )
  );

  readonly form = new UntypedFormGroup({
    cancellationType: new UntypedFormControl(CancellationType.CURRENT),
    message: new UntypedFormControl('')
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly bookingNavigationService: BookingNavigationService,
    private readonly privateSessionStore: SelectedPrivateSessionStore,
    private readonly privateSessionService: PrivateSessionsService,
    private analyticsService: AnalyticsService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    @Optional() private readonly dialogRef?: PuiDialogRef
  ) {
    this.state = this.router.getCurrentNavigation()?.extras.state;

    const cancellationType$: Observable<CancellationType> = this.form.controls.cancellationType.valueChanges.pipe(
      startWith(this.form.controls.cancellationType.value as CancellationType)
    );
    this.bookingsSelected$ = combineLatest([
      this.privateSessionStore.sessionEvents$,
      this.session$,
      cancellationType$
    ]).pipe(
      map(([bookings, session, cancellationType]) => {
        const isRecurringOffer = isOffer(session?.status!) && Array.isArray(session?.recurringSessionsDetails);
        if (cancellationType === CancellationType.RECURRING || isRecurringOffer) {
          this.form.controls.cancellationType.setValue(CancellationType.RECURRING, { emitEvent: false });
          return [...bookings];
        } else {
          return [{ id: session?.id!, date: session?.dateStart!, duration: session?.duration! }];
        }
      })
    );
  }

  getTitle(session: PrivateSession): string {
    const start = isOfferOrPending(session.status) ? `Decline` : `Cancel`;

    const recurring = session.recurringSessionsDetails?.length;
    if (recurring && recurring > 1) {
      return `${start} ${recurring} x ${session.name}`;
    }

    return `${start} ${session.name}?`;
  }

  cancel(
    session: PrivateSession,
    { message, cancellationType }: { message: string; cancellationType: CancellationType } = this.form.getRawValue()
  ): void {
    this.isSending$.next(true);
    const cancelAllRecurring = cancellationType !== CancellationType.CURRENT;
    this.cancelByType$(session, message, cancelAllRecurring)
      .pipe(
        take(1),
        tap(() => this.analyticsService.emitEvent(new SessionCanceledEvent())),
        finalize(() => {
          this.isSending$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const state: BookingCancellationConfirmationState = { cancel: cancellationType };
        this.bookingNavigationService.nextStep({ state });
      });
  }

  close(
    session: PrivateSession & {
      isTeamWorkspace: boolean;
      workspaceId: number;
    },
    bookings: Bookings[]
  ): void {
    this.dialogRef?.close();

    const isModal = this.route.snapshot.queryParams.isModal;

    if (!isModal) {
      this.redirectBack(session, bookings);
    }
  }

  isSelectSessionBlock(session: PrivateSession, bookings: Bookings[]): boolean {
    return !isOffer(session.status) && Array.isArray(bookings) && bookings.length > 1;
  }

  private cancelByType$(session: PrivateSession, message: string, cancelAllRecurring: boolean): Observable<unknown> {
    return isOffer(session.status)
      ? this.privateSessionService.declineSession$(session.id, message, cancelAllRecurring)
      : this.privateSessionService.cancelSession$(session.id, message, cancelAllRecurring);
  }

  private redirectBack(
    session: PrivateSession & {
      isTeamWorkspace: boolean;
      workspaceId: number;
    },
    bookings: Bookings[]
  ): void {
    const serviceId = session.templateId;

    let url = '';

    if (session.isTeamWorkspace) {
      url += '/t/' + session.workspaceId;
    }

    url += `/${session.guideId}/${serviceId}/confirmation`;

    if (this.route.snapshot.queryParams?.reschedule) {
      url += '/reschedule-complete';
    } else {
      url += '/complete';
    }

    url = convertQueryParamsToString(url, {
      ...this.route.snapshot.queryParams,
      ...{ session: JSON.stringify(session) },
      ...{ bookings: JSON.stringify(bookings) }
    });
    this.router.navigateByUrl(url, {
      state: { ...this.state, bookings }
    });
  }
}
